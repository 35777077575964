import { VehicleBrandResource } from '@data/vehicles/vehicles.model';
import { TyreTypeResource } from '@data/tyres/tyres.model';
import { TyreParamGroupResource } from '@data/tyre-param-group/tyre-param-group.model';
import { FilterParams } from '@data/filtering/filtering.interface';
import { Id } from '@core/http/crud-model';

export type TyreParamTypeName = 'run_flat' | 'reinforcement' | 'protector' | 'visual' | 'other' | 'approval';

export class TyreParamType {
  static RUN_FLAT: TyreParamTypeName = 'run_flat';
  static REINFORCEMENT: TyreParamTypeName = 'reinforcement';
  static PROTECTOR: TyreParamTypeName = 'protector';
  static VISUAL: TyreParamTypeName = 'visual';
  static OTHER: TyreParamTypeName = 'other';
  static APPROVAL: TyreParamTypeName = 'approval';

  static all(): TyreParamTypeName[] {
    return [
      TyreParamType.RUN_FLAT,
      TyreParamType.REINFORCEMENT,
      TyreParamType.PROTECTOR,
      TyreParamType.VISUAL,
      TyreParamType.OTHER,
      TyreParamType.APPROVAL,
    ];
  }

  static getTranslateKey(name: TyreParamTypeName): string {
    return 'tyre-param-type.' + name;
  }
}

export class TyreParam {
  static getParamsByType(params: TyreParamResource[], type: TyreParamTypeName): TyreParamResource[] | undefined {
    if (!params) {
      return [];
    }
    return params.filter((p) => p.type === type);
  }
}

export interface TyreParamResource {
  id: Id;
  code: string;
  type: string;
  brands: VehicleBrandResource;
  tyre_types: TyreTypeResource[];
  tyre_param_group: TyreParamGroupResource | null;
}

export type TyreParamFilterParams = FilterParams;

export interface TyreParamPostRequest {
  name: string;
  code: string;
  type: TyreParamTypeName;
  tyre_param_group_id: Id;
}

export interface TyreParamPutRequest {
  name: string;
  code: string;
  type: TyreParamTypeName;
  tyre_param_group_id: Id;
}
