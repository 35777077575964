import { NoteResource } from '@data/note/note.model';
import { InputOptionResource } from '@app/@feed-inputs';
import { ServiceResource } from '../services/services.interface';
import { Id } from '@app/@core/http/crud-model';
import { RimTypeValue, TyreStateValue } from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import { DispositionStatusName } from '@data/dispositions/dispositions.model';
import { VehicleResource } from '@data/vehicles/vehicles.model';
import { ProductTyreResource } from '../product-tyres/product-tyres.model';
import { WorkshopResource } from '../workshop/workshop.model';
import { ProducerResource } from '../producer/producer.model';
import { DateTimeString } from '@shared/interfaces/date-string';
import { RecipientPostRequest } from '@data/recipients/recipients.model';
import { Rim } from '@data/rim/rim.model';
import { LogUserResource } from '@data/activities/activities.model';

// TODO: cleanup
export interface TyreResource {
  id: string;
  description: string;
  // tread_depth: number;
  // tyre_position: TyrePositionSlug;
  // workshop_code: string;
  // location_in_workshop: string;
  // has_cap: boolean;
  tread_name: string;
  height: number;
  width: number;
  diameter: string;
  load_index: string;
  speed_index: string;
  rolling_resistance: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';
  adhesion: 'A' | 'B' | 'C' | 'D' | 'E' | 'F';
  noise: string;
  noise_level: number;
  dot: string;
  // rim_type: 'aluminum' | 'steel';
  added_at: string;
  expired_at: string;
  status?: TyreStateResource<TyreLogStatusName>;
  // vehicle: VehicleResource;
  tyre_season: TyreSeasonResource;
  producer: ProducerResource;
  // workshop: WorkshopResource;
  notes: NoteResource[];
  // deposit_code: string;
  created_at: string;
  updated_at: string;
  //
  current_log: TyreLogResource;
  draft_log: TyreLogResource;
  product_tyre: ProductTyreResource;
  deposit_states_history: TyreDepositDateResource[];
  logs_history: TyreLogsHistoryResource[];
  receive_date: DateTimeString;
}

export interface TyreLogResource {
  id: number;
  tyre_id: number;
  origin_tyre_log_id: Id;
  tyre_state_from: TyreStateValue;
  tyre_state_to: TyreStateValue;
  tread_depth: string;
  workshop_code: string;
  location_in_workshop: string;
  has_cap: boolean;
  status: DispositionStatusName;
  rim_type: RimTypeValue;
  completion_type: CompletionTypeSlug;
  tyre_position: TyrePositionSlug;
  tyre_state: TyreStateResource<TyreStateValue>;
  tyre_additional_states: TyreStateResource<TyreAdditionalStateValue>[];
  description: string;
  deposit_code: string;
  dep_updated_at: string;
  dep_cycle_counter: number;
  dep_cycle_max_length: number;
  dep_expected_end_date: string;
  reservation_end_date: string;
  created_at: string;
  updated_at: string;
  workshop: WorkshopResource;
  vehicle: VehicleResource;
  tyre: TyreResource;
  added_at: string;
  expired_at: string;
}

export interface TyreStateResource<T> {
  value: T;
  label: string;
  hex: string;
}

export interface TyreSeasonResource {
  id: string;
  name: string;
}

export interface TyreClassResource {
  id: string;
  name: string;
}

export type TyreLogOnCar = TyreLogWithMeta;
export type TyreLogInDeposit = TyreLogWithMeta;

export interface TyreLogWithMeta {
  data: TyreLogResource;
  meta?: {
    position?: TyrePositionSlug;
    hasRequiredDepth?: boolean;
    requiredDepth?: number;
    touched?: boolean;
    editing?: boolean;
    origin: 'car' | 'deposit';
    isNew?: boolean;
    season: string;
    maxDepth?: number;
    highlight: boolean;
    description?: string;
    rimType?: RimTypeValue;
    deletable: boolean;
  };
}

export interface WheelDamage {
  damage: InputOptionResource<{ remarks: boolean; action: 'seasonal_prefilled_disabled_tracking' | null }>;
  repair: ServiceResource;
  remarks?: string;
}

export type TyrePositionSlug =
  | 'left_front'
  | 'right_front'
  | 'left_rear'
  | 'right_rear'
  | 'spare'
  | 'left_rear_2'
  | 'right_rear_2';

export class TyrePosition {
  public static LEFT_FRONT: TyrePositionSlug = 'left_front';
  public static LEFT_REAR: TyrePositionSlug = 'left_rear';
  public static LEFT_REAR_2: TyrePositionSlug = 'left_rear_2';
  public static RIGHT_FRONT: TyrePositionSlug = 'right_front';
  public static RIGHT_REAR: TyrePositionSlug = 'right_rear';
  public static RIGHT_REAR_2: TyrePositionSlug = 'right_rear_2';
  public static SPARE: TyrePositionSlug = 'spare';

  public static isSpare(position: TyrePositionSlug): boolean {
    return position === TyrePosition.SPARE;
  }

  public static allFourWheelPositions(): TyrePositionSlug[] {
    return [
      TyrePosition.LEFT_FRONT,
      TyrePosition.LEFT_REAR,
      TyrePosition.RIGHT_FRONT,
      TyrePosition.RIGHT_REAR,
      TyrePosition.SPARE,
    ];
  }

  public static allSixWheelPositions(): TyrePositionSlug[] {
    return [
      TyrePosition.LEFT_FRONT,
      TyrePosition.LEFT_REAR,
      TyrePosition.RIGHT_FRONT,
      TyrePosition.RIGHT_REAR,
      TyrePosition.LEFT_REAR_2,
      TyrePosition.RIGHT_REAR_2,
      TyrePosition.SPARE,
    ];
  }

  public static allWithoutSpare(): TyrePositionSlug[] {
    return [TyrePosition.LEFT_FRONT, TyrePosition.LEFT_REAR, TyrePosition.RIGHT_FRONT, TyrePosition.RIGHT_REAR];
  }

  public static translateKey(position: TyrePositionSlug): string {
    return 'tyre-position.abbr.' + position;
  }
}

export interface TyrePropertyDimension {
  key: string;
  data: TyrePropertyDimensionData;
}

export interface TyrePropertyDimensionData {
  width: number;
  height: number;
  diameter: number;
}

export type TyreSeasonSlug = 'summer' | 'winter' | 'yearly';

export interface PostTyrePayload {
  workshop_id: number;
  vehicle_id: number;
  product_tyre_id?: number;
  description: string;
  tread_depth?: number;
  workshop_code: string;
  location_in_workshop: string;
  has_cap: boolean;
  tyre_state: string;
  tyre_additional_states: TyreAdditionalStateValue[];
  tyre_position: string;
  completion_type: CompletionTypeSlug;
  tyre_season: string;
  producer_id: number;
  rim_type: string;
  tread_name: string;
  height: number;
  width: number;
  diameter: string;
  load_index: string;
  speed_index: string;
  rolling_resistance: string;
  adhesion: string;
  noise: string;
  noise_level?: number;
  dot: string;
  damage_type_id?: number;
  added_at?: string;
  status: TyreLogStatusName;
  disposition_id?: Id;
  origin_tyre_log_id?: Id;
}

export type NoiseLevel = number;
export type Noise = '1' | '2' | '3' | 'A' | 'B' | 'C';

export class NoiseClass {
  public static OLD_1: Noise = '1';
  public static OLD_2: Noise = '2';
  public static OLD_3: Noise = '3';
  public static NEW_A: Noise = 'A';
  public static NEW_B: Noise = 'B';
  public static NEW_C: Noise = 'C';

  public static firstClass(noise: Noise): boolean {
    return [this.OLD_1, this.NEW_A].includes(noise);
  }

  public static secondClass(noise: Noise): boolean {
    return [this.OLD_2, this.NEW_B].includes(noise);
  }

  public static thirdClass(noise: Noise): boolean {
    return [this.OLD_3, this.NEW_C].includes(noise);
  }
}

export type RollingResistance = string;
export type Adhesion = string;

export interface AssignToVehiclePatchRequest {
  vehicle_id: number;
  tyre_ids: Id[];
  password?: string;
  comment?: string;
}

export interface DisposalPatchRequest {
  tyre_ids: Id[];
  description: string;
}

export type ReleaseDriverPatchRequest = ReleaseDriverWithDataPatchRequest | ReleaseDriverWithIdPatchRequest;

export interface ReleaseDriverWithDataPatchRequest {
  release_to: string;
  description: string;
  tyre_ids: Id[];
  driver_data: {
    name: string;
    phone_number: string;
    email?: string;
    blocked_at?: string;
  };
}

export interface ReleaseDriverWithIdPatchRequest {
  release_to: string;
  description: string;
  tyre_ids: Id[];
  driver_id: Id;
}

export type ReleaseRecipientPatchRequest = ReleaseRecipientWithIdPatchRequest | ReleaseRecipientWithDataPatchRequest;

export interface ReleaseRecipientWithIdPatchRequest {
  release_to: string;
  description: string;
  tyre_ids: Id[];
  recipient_id: number;
}

export interface ReleaseRecipientWithDataPatchRequest {
  release_to: string;
  description: string;
  tyre_ids: Id[];
  recipient_data: RecipientPostRequest;
}

export interface TyreTypeResource {
  id: number;
  name: string;
}

export interface TyreSeason {
  id: string;
  name: string;
}

export type TyreLogStatusName = 'draft' | 'approved' | 'current' | 'canceled';

export class TyreLogStatus {
  public static DRAFT: TyreLogStatusName = 'draft';
  public static APPROVED: TyreLogStatusName = 'approved';
  public static CURRENT: TyreLogStatusName = 'current';
  public static CANCELED: TyreLogStatusName = 'canceled';
}

export type TyreAdditionalStateValue =
  | 'new-tyre'
  | 'waiting'
  | 'reservation'
  | 'relocation'
  | 'spare'
  | 'sale'
  | 'released'
  | 'accepted'
  | 'repair'
  | 'installation'
  | 'reserve'
  | 'pending-release'
  | 'paid-deposit'
  | 'deposit-extension';

export class TyreAdditionalState {
  public static NEW_TYRE: TyreAdditionalStateValue = 'new-tyre';
  public static WAITING: TyreAdditionalStateValue = 'waiting';
  public static RESERVATION: TyreAdditionalStateValue = 'reservation';
  public static RELOCATION: TyreAdditionalStateValue = 'relocation';
  public static SPARE: TyreAdditionalStateValue = 'spare';
  public static SALE: TyreAdditionalStateValue = 'sale';
  public static RELEASED: TyreAdditionalStateValue = 'released';
  public static ACCEPTED: TyreAdditionalStateValue = 'accepted';
  public static REPAIR: TyreAdditionalStateValue = 'repair';
  public static INSTALLATION: TyreAdditionalStateValue = 'installation';
  public static RESERVE: TyreAdditionalStateValue = 'reserve';
  public static PENDING_RELEASE: TyreAdditionalStateValue = 'pending-release';
  public static PAID_DEPOSIT: TyreAdditionalStateValue = 'paid-deposit';
  public static DEPOSIT_EXTENSION: TyreAdditionalStateValue = 'deposit-extension';

  public static disposition(): TyreAdditionalStateValue[] {
    return [
      TyreAdditionalState.NEW_TYRE,
      TyreAdditionalState.RESERVATION,
      TyreAdditionalState.SPARE,
      TyreAdditionalState.SALE,
      TyreAdditionalState.RELEASED,
      TyreAdditionalState.ACCEPTED,
      TyreAdditionalState.REPAIR,
      TyreAdditionalState.INSTALLATION,
    ];
  }

  public static depositAction(): TyreAdditionalStateValue[] {
    return [TyreAdditionalState.RELOCATION, TyreAdditionalState.RESERVE, TyreAdditionalState.PENDING_RELEASE];
  }

  public static depositPayment(): TyreAdditionalStateValue[] {
    return [TyreAdditionalState.WAITING, TyreAdditionalState.PAID_DEPOSIT, TyreAdditionalState.DEPOSIT_EXTENSION];
  }

  public static editable(): TyreAdditionalStateValue[] {
    return [...TyreAdditionalState.depositAction(), TyreAdditionalState.RESERVATION];
  }

  public static getDispositionStatus(
    states: TyreStateResource<TyreAdditionalStateValue>[],
  ): TyreStateResource<TyreAdditionalStateValue> | undefined {
    return states.find((state: TyreStateResource<TyreAdditionalStateValue>) =>
      TyreAdditionalState.disposition().includes(state.value),
    );
  }

  public static getDepositPaymentStatus(
    states: TyreStateResource<TyreAdditionalStateValue>[],
  ): TyreStateResource<TyreAdditionalStateValue> | undefined {
    return states.find((state: TyreStateResource<TyreAdditionalStateValue>) =>
      TyreAdditionalState.depositPayment().includes(state.value),
    );
  }

  public static getDepositActionStatus(
    states: TyreStateResource<TyreAdditionalStateValue>[],
  ): TyreStateResource<TyreAdditionalStateValue> | undefined {
    return states.find((state: TyreStateResource<TyreAdditionalStateValue>) =>
      TyreAdditionalState.depositAction().includes(state.value),
    );
  }

  public static getEditableStatus(
    states: TyreStateResource<TyreAdditionalStateValue>[],
  ): TyreStateResource<TyreAdditionalStateValue> | undefined {
    return states.find((state: TyreStateResource<TyreAdditionalStateValue>) =>
      TyreAdditionalState.editable().includes(state.value),
    );
  }

  public static removeableInNewDisposition(): TyreAdditionalStateValue[] {
    return TyreAdditionalState.disposition().filter(
      (s) => ![TyreAdditionalState.SPARE, TyreAdditionalState.RESERVATION].includes(s),
    );
  }

  public static getReservation(
    states: TyreStateResource<TyreAdditionalStateValue>[],
  ): TyreStateResource<TyreAdditionalStateValue> | undefined {
    return states.find((state: TyreStateResource<TyreAdditionalStateValue>) =>
      [TyreAdditionalState.RESERVATION].includes(state.value),
    );
  }

  public static getNew(
    states: TyreStateResource<TyreAdditionalStateValue>[],
  ): TyreStateResource<TyreAdditionalStateValue> | undefined {
    return states.find((state: TyreStateResource<TyreAdditionalStateValue>) =>
      [TyreAdditionalState.NEW_TYRE].includes(state.value),
    );
  }

  public static getTranslateKey(s: TyreAdditionalStateValue): string {
    return 'tyre-additional-state.' + s;
  }
}

export type TyreTypeName =
  | 'car'
  | 'van'
  | 'suv_4x4'
  | 'truck'
  | 'industrial'
  | 'agricultural'
  | 'motorcycle'
  | 'scooter'
  | 'quad';

export class TyreType {
  static CAR: TyreTypeName = 'car';
  static VAN: TyreTypeName = 'van';
  static SUV_4X4: TyreTypeName = 'suv_4x4';
  static TRUCK: TyreTypeName = 'truck';
  static INDUSTRIAL: TyreTypeName = 'industrial';
  static AGRICULTURAL: TyreTypeName = 'agricultural';
  static MOTORCYCLE: TyreTypeName = 'motorcycle';
  static SCOOTER: TyreTypeName = 'scooter';
  static QUAD: TyreTypeName = 'quad';

  static all(): TyreTypeName[] {
    return [
      TyreType.CAR,
      TyreType.VAN,
      TyreType.SUV_4X4,
      TyreType.TRUCK,
      TyreType.INDUSTRIAL,
      TyreType.AGRICULTURAL,
      TyreType.MOTORCYCLE,
      TyreType.SCOOTER,
      TyreType.QUAD,
    ];
  }
}

export type CompletionTypeSlug = 'complete_wheel' | 'tyre_only' | 'rim_only';

export class CompletionType {
  public static COMPLETE_WHEEL: CompletionTypeSlug = 'complete_wheel';
  public static TYRE_ONLY: CompletionTypeSlug = 'tyre_only';
  public static RIM_ONLY: CompletionTypeSlug = 'rim_only';

  static getTranslateKey(value: CompletionTypeSlug): string {
    return 'completion-type.' + value;
  }

  static isCompleteWheel(value: CompletionTypeSlug): boolean {
    return [CompletionType.COMPLETE_WHEEL].includes(value);
  }
}

export interface TyrePatchRequestParams {
  tread_depth?: string;
  tyre_position?: string;
  completion_type?: CompletionTypeSlug;
  product_tyre_id?: Id;
  tyre_additional_states?: TyreAdditionalStateValue[];
  dot?: string;
  rim_type?: RimTypeValue;
  deposit_code?: string;
}

export interface TyreSeasons {
  winter: TyreDiscountSeason;
  summer: TyreDiscountSeason;
  yearly: TyreDiscountSeason;
}

export interface TyreDiscountSeason {
  id: number;
  group: string;
  discount: number;
  created_at: string;
  updated_at: string;
}

export interface TyreDepositDateResource {
  state: TyreAdditionalStateValue;
  dep_updated_at: DateTimeString;
}

export interface TyreClass {
  id: TyreClassesSlug;
  name: TyreClassesSlug;
}

export type TyreClassesSlug = 'premium' | 'medium' | 'budget';

export class TyreClasses {
  public static PREMIUM: TyreClassesSlug = 'premium';
  public static MEDIUM: TyreClassesSlug = 'medium';
  public static BUDGET: TyreClassesSlug = 'budget';
}

export interface TyreLogsHistoryResource {
  additional_states_added: TyreAdditionalStateValue[];
  additional_states_removed: TyreAdditionalStateValue[];
  changes: Omit<TyreLogResource, 'created_at' | 'updated_at' | 'tyre_log_additional_states'>;
  previous_state: Omit<TyreLogResource, 'created_at' | 'updated_at' | 'tyre_log_additional_states'>;
  created_at: DateTimeString;
  updated_by: LogUserResource;
}

export class TyreLogWithChange {
  constructor(
    public readonly tyreLog: TyreLogResource,
    public readonly isRim: boolean,
  ) {}

  getName(): string {
    if (!this.isRim) {
      return null;
    }

    return new Rim(this.tyreLog).getName();
  }
}

export class TyreAdditionalStatusEdit {
  notEditable: TyreAdditionalStateValue[];

  constructor(
    private current: TyreAdditionalStateValue[],
    private availableToChange: TyreAdditionalStateValue[],
  ) {
    this.notEditable = this.current.filter((s) => !this.availableToChange.includes(s));
  }

  getRequest(newStatus: TyreAdditionalStateValue): TyreAdditionalStateValue[] {
    if (newStatus) {
      return [...this.notEditable, newStatus];
    }
    return this.notEditable;
  }
}
