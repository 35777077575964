import { Component, Input, OnInit } from '@angular/core';
import { InfoTooltipComponent } from '../info-tooltip.component';

@Component({
  selector: 'wchfs-info-tooltip-toggle',
  templateUrl: 'info-tooltip-toggle.component.html',
  styleUrls: ['info-tooltip-toggle.component.scss'],
})
export class InfoTooltipToggleComponent implements OnInit {
  @Input() tooltip: InfoTooltipComponent;
  @Input() relativeEl: Element;
  @Input() forceRightAuto = false;

  open(event: Event): void {
    event.stopPropagation();
    this.tooltip.openTooltip();
  }

  ngOnInit() {
    this.updateTooltipPos();
  }

  updateTooltipPos() {
    if (
      this.forceRightAuto ||
      this.tooltip.elementRef.nativeElement.getBoundingClientRect().left < this.relativeEl.getBoundingClientRect().width
    ) {
      this.tooltip.elementRef.nativeElement.style.right = 'auto';
    }
  }
}
