import { ProductTyreResource } from '@data/product-tyres/product-tyres.model';
import { Adhesion, Noise, NoiseLevel, RollingResistance, TyreResource } from '@data/tyres/tyres.model';
import { DashboardDepositResource } from '@data/dashboard/dashboard.model';
import { TyreParamResource } from '@data/tyre-params/tyre-params.model';

export interface TyreInfo {
  getProductTyre(): ProductTyreResource;

  getTyreParams(): TyreParamResource[];

  hasTyreParams(): boolean;

  getRollingResistance(): RollingResistance;

  hasRollingResistance(): boolean;

  getAdhesion(): Adhesion;

  hasAdhesion(): boolean;

  getNoiseLevel(): NoiseLevel;

  getNoise(): Noise;

  hasNoiseOrNoiseLevel(): boolean;
}

export class ProductTyreTyreInfo implements TyreInfo {
  constructor(private resource: ProductTyreResource) {}

  getProductTyre(): ProductTyreResource {
    return this.resource;
  }

  getTyreParams(): TyreParamResource[] {
    return this.resource?.tyre_params;
  }

  hasTyreParams(): boolean {
    return this.resource?.tyre_params?.length > 0;
  }

  getRollingResistance(): string {
    return this.resource?.rolling_resistance;
  }

  hasRollingResistance(): boolean {
    return !!this.resource?.rolling_resistance;
  }

  getAdhesion(): Adhesion {
    return this.resource?.adhesion;
  }

  hasAdhesion(): boolean {
    return !!this.resource?.adhesion;
  }

  getNoiseLevel(): NoiseLevel {
    return this.resource?.noise_level;
  }

  hasNoiseOrNoiseLevel(): boolean {
    return this.resource?.noise_level || this.resource?.noise;
  }

  getNoise(): Noise {
    return this.resource?.noise;
  }
}

export class TyreTyreInfo implements TyreInfo {
  constructor(private resource: TyreResource) {}

  getProductTyre(): ProductTyreResource {
    return this.resource?.product_tyre;
  }

  getTyreParams(): TyreParamResource[] {
    return this.resource?.product_tyre?.tyre_params;
  }

  hasTyreParams(): boolean {
    return this.resource?.product_tyre?.tyre_params?.length > 0;
  }

  getRollingResistance(): string {
    return this.resource?.rolling_resistance ?? this.resource?.product_tyre?.rolling_resistance;
  }

  hasRollingResistance(): boolean {
    return !!(this.resource?.rolling_resistance || this.resource?.product_tyre?.rolling_resistance);
  }

  getAdhesion(): Adhesion {
    return this.resource?.adhesion ?? this.resource?.product_tyre?.adhesion;
  }

  hasAdhesion(): boolean {
    return !!(this.resource?.adhesion || this.resource?.product_tyre?.adhesion);
  }

  getNoiseLevel(): NoiseLevel {
    return this.resource?.noise_level ?? this.resource?.product_tyre?.noise_level;
  }

  hasNoiseOrNoiseLevel(): boolean {
    return (
      this.resource?.noise_level ||
      this.resource?.noise ||
      this.resource?.product_tyre?.noise_level ||
      this.resource?.product_tyre?.noise
    );
  }

  getNoise(): Noise {
    return this.resource?.noise ?? this.resource?.product_tyre?.noise;
  }
}

export class DashboardDepositTyreInfo implements TyreInfo {
  constructor(private resource: DashboardDepositResource) {}

  getProductTyre(): ProductTyreResource {
    return this.resource as unknown as ProductTyreResource;
  }

  getTyreParams(): TyreParamResource[] {
    return this.resource?.tyre_params;
  }

  hasTyreParams(): boolean {
    return this.resource?.tyre_params?.length > 0;
  }

  getRollingResistance(): string {
    return this.resource?.rolling_resistance;
  }

  hasRollingResistance(): boolean {
    return !!this.resource?.rolling_resistance;
  }

  getAdhesion(): Adhesion {
    return this.resource?.adhesion;
  }

  hasAdhesion(): boolean {
    return !!this.resource?.adhesion;
  }

  getNoiseLevel(): NoiseLevel {
    return this.resource?.noise_level;
  }

  hasNoiseOrNoiseLevel(): boolean {
    return !!(this.resource?.noise_level || this.resource?.noise);
  }

  getNoise(): Noise {
    return this.resource?.noise;
  }
}
