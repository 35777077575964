import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductTyreResource } from '@app/@data/product-tyres/product-tyres.model';
import { TyreResource } from '@app/@data/tyres/tyres.model';
import {
  DashboardDepositTyreInfo,
  ProductTyreTyreInfo,
  TyreInfo,
  TyreTyreInfo,
} from '@pso-ui/tyre-info/tyre-info.model';
import { DashboardDepositResource } from '@data/dashboard/dashboard.model';
import { TyreParam, TyreParamType } from '@data/tyre-params/tyre-params.model';

@Component({
  selector: 'app-tyre-info',
  templateUrl: './tyre-info.component.html',
  styleUrls: ['./tyre-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TyreInfoComponent {
  get dashboardDeposit(): DashboardDepositResource {
    return this._dashboardDeposit;
  }

  @Input()
  set dashboardDeposit(value: DashboardDepositResource) {
    this.tyreInfo = new DashboardDepositTyreInfo(value);
    this._dashboardDeposit = value;
  }

  get productTyre(): ProductTyreResource {
    return this._productTyre;
  }

  @Input()
  set productTyre(value: ProductTyreResource) {
    this.tyreInfo = new ProductTyreTyreInfo(value);
    this._productTyre = value;
  }

  get tyre(): TyreResource {
    return this._tyre;
  }

  @Input()
  set tyre(value: TyreResource) {
    this.tyreInfo = new TyreTyreInfo(value);
    this._tyre = value;
  }

  private _dashboardDeposit: DashboardDepositResource;
  private _tyre: TyreResource;
  private _productTyre: ProductTyreResource;
  TyreParam = TyreParam;
  TyreParamType = TyreParamType;
  tyreInfo: TyreInfo;
}
