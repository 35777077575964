import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { LegacyFilterParams, LaravelFilterParams } from '@shared/utils/laravel-filter-params';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { price } from '@shared/utils/price';
import { NotificationService } from '@shared/services/notification.service';
import {
  FiltersSectionComponentHidingButtonMode,
  FiltersSectionTheme,
} from '@pso-ui/filters-section/filters-section.component.model';

@Component({
  selector: 'app-filters-section',
  templateUrl: './filters-section.component.html',
  styleUrls: ['./filters-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersSectionComponent implements OnInit {
  @Input() searchFormQuery: UntypedFormGroup;
  @Input() mode: FiltersSectionComponentHidingButtonMode = FiltersSectionComponentHidingButtonMode.ALL;
  @Input() hideOnInit = false;
  @HostBinding('class')
  @Input()
  theme = FiltersSectionTheme.LIST;
  @Output() submitEvent = new EventEmitter();
  @Output() resetEvent = new EventEmitter();

  hide: boolean;
  customErrors = {
    minlength: this.translateService.instant('input-errors.minlength', { value: 3 }),
  };
  protected readonly FiltersSectionComponentHidingButtonMode = FiltersSectionComponentHidingButtonMode;

  constructor(
    private translateService: TranslateService,
    protected cdr: ChangeDetectorRef,
    protected notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.hide = this.hideOnInit;
  }

  getControl(name: string): UntypedFormControl {
    return this.searchFormQuery.get(name) as UntypedFormControl;
  }

  onHideButtonClick(): void {
    this.hide = !this.hide;
  }

  onFormSubmit(): void {
    const filteredAndMultipliedValues: LegacyFilterParams = {};
    Object.keys(this.searchFormQuery.controls).forEach((controlName) => {
      // Check if the control name includes "price"
      if (controlName.includes('price') && this.searchFormQuery.get(controlName).value) {
        filteredAndMultipliedValues[controlName] = price(this.searchFormQuery.get(controlName).value);
      } else {
        filteredAndMultipliedValues[controlName] = this.searchFormQuery.get(controlName).value;
      }
    });

    const laravelFilterParams: LegacyFilterParams = new LaravelFilterParams(
      filteredAndMultipliedValues,
    ).getFlatParams();
    this.submitEvent.emit(laravelFilterParams);
  }

  clearFilters(): void {
    this.searchFormQuery.reset();
    this.resetEvent.emit();
  }

  getShowLabel(): string {
    switch (this.mode) {
      case FiltersSectionComponentHidingButtonMode.ALL: {
        return 'show';
      }
      case FiltersSectionComponentHidingButtonMode.SECOND_ROW: {
        return 'show-more-filters';
      }
    }
  }

  getHideLabel(): string {
    switch (this.mode) {
      case FiltersSectionComponentHidingButtonMode.ALL: {
        return 'hide';
      }
      case FiltersSectionComponentHidingButtonMode.SECOND_ROW: {
        return 'show-less-filters';
      }
    }
  }
}
