<div class="filters">
  <div
    class="filters-content"
    [style.display]="hide && mode === FiltersSectionComponentHidingButtonMode.ALL ? 'none' : ''"
  >
    <div class="grid-wrapper">
      <ng-content select=".grid-content"></ng-content>
      <div [style.display]="hide && mode === FiltersSectionComponentHidingButtonMode.SECOND_ROW ? 'none' : ''">
        <ng-content select=".grid-content-2"></ng-content>
      </div>
    </div>
    <ng-content select=".outside-content"></ng-content>
  </div>

  <div class="buttons-wrapper">
    <button (click)="$event.preventDefault(); onHideButtonClick()" type="button" class="toggle-button">
      <ng-container *ngIf="hide"
        >{{ getShowLabel() | translate | uppercase }}
        <wchfs-icon svgIcon="pso-legacy:triangle-arrow-down" size="24" color="grey-3"></wchfs-icon>
      </ng-container>
      <ng-container *ngIf="!hide"
        >{{ getHideLabel() | translate | uppercase }}
        <wchfs-icon
          svgIcon="pso-legacy:triangle-arrow-down"
          [style.rotate]="'180deg'"
          size="24"
          color="grey-3"
        ></wchfs-icon>
      </ng-container>
    </button>

    <div class="buttons-right">
      <button
        [dataCy]="'filter-clear-btn'"
        pso-outline-button
        [style.visibility]="hide && mode === FiltersSectionComponentHidingButtonMode.ALL ? 'hidden' : 'inherit'"
        type="button"
        (click)="clearFilters()"
      >
        {{ 'clear' | translate | uppercase }}
      </button>

      <button
        [dataCy]="'filter-submit-btn'"
        pso-button
        [color]="'primary-gradient'"
        [disabled]="!searchFormQuery.valid"
        [style.visibility]="hide && mode === FiltersSectionComponentHidingButtonMode.ALL ? 'hidden' : 'inherit'"
        (click)="onFormSubmit()"
        type="submit"
      >
        {{ 'search' | translate | uppercase }}
      </button>
    </div>
  </div>
</div>
