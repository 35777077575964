import { DispositionStatusName } from '@data/dispositions/dispositions.model';
import { DateTimeString } from '@shared/interfaces/date-string';
import { RelocationsStatusSlug } from '@data/relocations/relocations.model';
import { OrderStatusValue } from '@data/orders/orders.model';
import { EnumResource } from '@shared/interfaces/enum-resource';
import { TyreStateValue } from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import { Noise, NoiseLevel } from '@data/tyres/tyres.model';
import {
  ReservationEventTypeResource,
  ReservationStatus,
  ReservationStatusValue,
} from '@data/reservations/reservations.model';
import { TyreParamResource } from '@data/tyre-params/tyre-params.model';

export interface DashboardDisposition {
  id: number;
  name: string;
  status: DispositionStatusName;
  created_at: DateTimeString;
}

export interface DashboardRelocation {
  id: number;
  status: RelocationsStatusSlug;
  delay: number;
  details: string;
  created_at: string;
  disposition_number: string;
  registration_number: string;
}

export interface DashboardDepositResource {
  rolling_resistance: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';
  adhesion: 'A' | 'B' | 'C' | 'D' | 'E' | 'F';
  noise: Noise;
  noise_level: NoiseLevel;
  code: string;
  count: number;
  created_at: string;
  status: string;
  tyre: string;
  tyre_params: TyreParamResource[];
}

export interface DashboardOrder {
  id: number;
  vehicle_registration_number: string;
  created_at: string;
  number: string;
  status: OrderStatusValue;
  details: string;
  quantity: number;
  reaction_time: string;
}

export interface DashboardReservation {
  id: number;
  status: EnumResource<ReservationStatusValue>;
  start_date: DateTimeString;
  end_date: DateTimeString;
  name: string;
}

export const DASHBOARD_ERROR_VALUE = 'error';

export class DashboardDeposit {
  constructor(private resource: DashboardDepositResource) {}

  getCode(): string {
    return this.resource?.code;
  }

  getStatus(): string {
    return this.resource?.status;
  }

  getNameFormatted(): string {
    return this.resource?.count + ' x ' + this.resource?.tyre;
  }

  getResource(): DashboardDepositResource {
    return this.resource;
  }
}
